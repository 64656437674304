import React from 'react';
import Slider from 'react-slick';

import './Galery.scss';

import picture1 from '../../images/Pictures/picture-1.jpg';
import picture2 from '../../images/Pictures/picture-2.jpg';
import picture3 from '../../images/Pictures/picture-3.jpg';
import picture4 from '../../images/Pictures/picture-4.jpg';
import picture5 from '../../images/Pictures/picture-5.jpg';
import picture6 from '../../images/Pictures/picture-6.jpg';
import picture7 from '../../images/Pictures/picture-7.jpg';
import picture8 from '../../images/Pictures/picture-8.jpg';
import picture9 from '../../images/Pictures/picture-9.jpg';
import picture10 from '../../images/Pictures/picture-10.jpg';
import picture11 from '../../images/Pictures/picture-11.jpg';
import picture12 from '../../images/Pictures/picture-12.jpg';
import picture13 from '../../images/Pictures/picture-13.jpg';
import picture14 from '../../images/Pictures/picture-14.jpg';
import picture15 from '../../images/Pictures/picture-15.jpeg';
import picture16 from '../../images/Pictures/picture-16.jpeg';
import picture17 from '../../images/Pictures/picture-17.jpeg';
import picture18 from '../../images/Pictures/picture-18.jpeg';

import arrow from './assets/arrow.svg';

const data = [
  {
    image: picture1,
    caption: ''
  },
  {
    image: picture2
  },
  {
    image: picture3
  },
  {
    image: picture4
  },
  {
    image: picture5
  },
  {
    image: picture6
  },
  {
    image: picture7
  },
  {
    image: picture8
  },
  {
    image: picture9
  },
  {
    image: picture10
  },
  {
    image: picture11
  },
  {
    image: picture12
  },
  {
    image: picture13
  },
  {
    image: picture14
  },
  {
    image: picture15
  },
  {
    image: picture16
  },
  {
    image: picture17
  },
  {
    image: picture18
  }
];

export const ArrowSide = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className}`}
      onClick={onClick}
    >
      <img src={arrow} alt="" />
    </div>
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerPadding: 0,
  adaptiveHeight: false,
  variableWidth: false,
  prevArrow: <ArrowSide />,
  nextArrow: <ArrowSide />,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    },
  ]
};

const Gallery = () => {

  return (
    <div className="gallery">
      <div className="container">
        <Slider {...settings}>
          {data.map(({ image }) => <img src={image} alt="" />)}
        </Slider>
      </div>
    </div>
  );
};

export default Gallery;
