import React, { useRef } from 'react';
import Slider from 'react-slick';
import { ArrowSide } from '../Gallery/Gallery';
import './PriceCategory.scss';

import price1 from '../../images/prices/cenovnik-0.svg';
import price2 from '../../images/prices/cenovnik-1.svg';
import price3 from '../../images/prices/cenovnik-2.svg';
import price4 from '../../images/prices/cenovnik-3.svg';
import price5 from '../../images/prices/cenovnik-4.svg';

const pages = [
  price1,
  price2,
  price3,
  price4,
  price5
];

const settings = {
  dots: false,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  slidesToScroll: 1,
  prevArrow: <ArrowSide />,
  nextArrow: <ArrowSide />
};

const PriceCategory = () => {
  const ref = useRef(null)
  const handleOnClick = index => {
    ref.current.slickGoTo(index);
  };
  return (
    <div className="price-category">
      <div className="floating-menu">
        <div onClick={() => handleOnClick(2)}>Tretmani lica</div>
        <div onClick={() => handleOnClick(0)}>Nega ruku i nogu</div>
        <div onClick={() => handleOnClick(1)}>Depilacija</div>
      </div>
      <Slider {...settings} ref={ref}>
        {pages.map((image, index) => <img key={index} src={image} alt="" />)}
      </Slider>
    </div>
  );
};

export default PriceCategory;
