import React from 'react';

import './Hero.scss';

import image from '../../images/Pictures/picture-8.jpg';

const Hero = () => {
  return (
    <div className="container" id="home">
      <div className="hero-section">
        <div>
          <div className="hero-title">
            CRYSTALLINE <br />STUDIO
            <div className="floating-box">
              <div className="label">
                by Mirjana Stevanović
              </div>
              <div className="label flipped">
                Skin & Beauty
              </div>
            </div>
          </div>
          <img className="hide-desktop" src={image} alt="Fotografija" />
          <div className="hero-description">
            <strong>Salon Crystalline Studio</strong> je počeo sa radom 25. februara 2013. godine od
            strane višeg
            estetičara-kozmetičara Mirjane Stevanović. Iz godine u godinu nastojimo da vam pružimo
            što kvalitetniju i bolju uslugu te težimo da se usavršavamo prisustvovajući različitim
            stručnim obukama, seminarima kao i edukacijama. Naš izbor kozmetike su francuski brendovi
            kao što su Sothys, Matis, Dr. Renaud a na posebno mesto među njima izdvajamo <strong>Biologique
            Recherche</strong> sa svojom ekskluzivnom metodologijom personalizovane kozmetičke nege
            prilagođene specijalno za vas.
          </div>
        </div>
        <div>
          <img className="hide-mobile" src={image} alt="Fotografija" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
