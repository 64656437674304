import React from 'react';

import './Footer.scss';

import map from './assets/map.svg';
import { links } from '../Header/Header';

const Footer = () => {
  return (
    <footer className="footer" id="kontakt">
      <div className="container">
        <div className="footer-section">
          <div className="footer-links">
            {
              links.map(({ title, link }) => <a
                  className="link"
                  href={link} {...(link.startsWith('#') ? null : { target: '_blank' })}
                >{title}</a>
              )
            }
          </div>
          <div className="studio">
            <div className="footer-title">
              crystalline studio.
            </div>
            <div className="footer-subtitle">
              Skin & Beauty
            </div>
            <div className="footer-subtitle">
              by Mirjana Stevanović
            </div>
            <a href="https://www.google.com/maps/dir/44.791424,20.4796054/crystalline+studio/@44.7980582,20.4467787,14z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x475a7143097d3809:0xffd2c58cae4af8ad!2m2!1d20.4581022!2d44.8049371" target="_blank">
              <img className="map" src={map} alt="Mapa" />
            </a>
            <div className="copyright">
              © 2021 made by nathalia♡. all right reserved.
            </div>
          </div>
          <div className="location-details">
            <div className="footer-label">
              TELEFON
            </div>
            <div className="footer-value">
              <a href="tel:+381113622586">
                011 / 36 22 586
              </a>
            </div>
            <div className="footer-label">
              ADRESA
            </div>
            <div className="footer-value">
              Birčaninova 8, Savski venac <br />
              11000 Beograd
            </div>
            <div className="footer-label">
              E-MAIL
            </div>
            <div className="footer-value">
              <a href="mailto:crystallinestudio@gmail.com">
                crystallinestudio@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
