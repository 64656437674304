import React from 'react';

import './Services.scss';

const Services = () => {
  return (
    <div className="services" id="usluge">
      <div className="container">
        <div className="section-title">
          Usluge
        </div>

        <div className="services-list">
          <div className="service-list-item">
            <div className="service-list-title">
              Tretmani lica
            </div>
            <div className="service-list-description">
              Naši tretmani lica predstavljaju individualni pristup određivanja tretmana
              specijalno za vas u zavisnosti od tipa kože, koristeći
              sve najkvalitetnije francuske brendove.
            </div>
          </div>
          <div className="service-list-item">
            <div className="service-list-title">
              Depilacija
            </div>
            <div className="service-list-description">
              Radi izuzetno higijenskog pristupa omogućili smo vam uslugu depilacije na
              najvišem nivou korišćenjem patrona hladnog voska, dok se u slučajevima
              toplog voska korisiti isključivo čist, neprečišćavan vosak za jednokratnu
              upotrebu.
            </div>
          </div>
          <div className="service-list-item">
            <div className="service-list-title">
              Nega noktiju ruku i nogu
            </div>
            <div className="service-list-description">
              Nudimo veliki asortiman običnih lakova
              brenda OPI, kao i trajnih lakova brendova Shellac, Art Nails i Galaxy
              Nails, po vašoj želji.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
