import * as React from "react"
import { Helmet } from "react-helmet"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import '../styles/index.scss'
import Header from '../components/Header/Header';
import Hero from '../components/Hero/Hero';
import Gallery from '../components/Gallery/Gallery';
import Services from '../components/Services/Services';
import Partners from '../components/Partners/Partners';
import Footer from '../components/Footer/Footer';
import Prices from '../components/Prices/Prices';

const Home = () => {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Crystalline Studio | Salon lepote</title>
        <link rel="canonical" href="https://crystalline.studio" />
      </Helmet>
      <Header />
      <Hero />
      <Gallery />
      <Services />
      <Partners />
      <Prices />
      <Footer />
    </main>
  )
}

export default Home
