import React from 'react';

import './Partners.scss';

import logo1 from '../../images/Logos/biologique-recherche-logo.svg';
import logo2 from '../../images/Logos/cnd-logo.svg';
import logo4 from '../../images/Logos/dr-renaud-logo.svg';
import logo5 from '../../images/Logos/matis-logo.svg';
import logo6 from '../../images/Logos/opi-logo.svg';
import logo7 from '../../images/Logos/sothys-logo.svg';

const logos = [
  {
    src: logo1,
    href: 'https://www.biologiquerecherchesrbija.com/'
  },
  {
    src: logo2,
    href: 'https://cnd.com/'
  },
  {
    src: logo4,
    href: 'https://www.ldrenaud.com/en'
  },
  {
    src: logo5,
    href: 'https://www.matis-paris.com/'
  },
  {
    src: logo6,
    href: 'https://www.opi.com/'
  },
  {
    src: logo7,
    href: 'https://www.sothys.fr/en'
  },
];

const Partners = () => {
  return (
    <div className="partners">
      <div className="container">
        <div className="partner-list">
          {logos.map(({ src, href }) =>
            <a href={href} target="_blank" rel="noopener noreferrer">
              <img src={src} alt="Logo" />
            </a>
          )}
        </div>
      </div>
    </div>
  );
};

export default Partners;
