import React, { useState } from 'react';

import './Header.scss';

import logo from '../../images/Logos/crystalline-logo.svg';
import menuIcon from './assets/menu.svg';
import { ReactComponent as menu } from './assets/menu.svg';

export const links = [
  {
    title: 'Usluge',
    link: '#usluge'
  },
  {
    title: 'Cenovnik',
    link: '#cenovnik'
  },
  {
    title: 'Instagram',
    link: 'https://www.instagram.com/crystallinestudiolepote/'
  },
  {
    title: 'Facebook',
    link: 'https://www.facebook.com/crystallinestudiolepote/'
  },
  {
    title: 'Kontakt',
    link: '#kontakt'
  }
];

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState();

  return (
    <header className={`header ${isMenuOpen ? 'active' : ''}`}>
      <div className="container">
        <nav className="desktop">
          <div className="logo">
            <a href="#home">
              <img src={logo} alt="Logo" />
            </a>
          </div>
          {
            links.map(({ title, link }) => <a
                href={link} {...(link.startsWith('#') ? null : { target: '_blank' })}
              >{title}</a>
            )
          }
        </nav>
        <nav className="mobile">
          <div className="logo">
            <a href="#home">
              <img src={logo} alt="Logo" />
            </a>
          </div>
          <div className="menu" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <svg className={`${isMenuOpen ? 'active' : ''}`} width="33" height="27" viewBox="0 0 33 27" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect id="rect-1" x="10" width="23" height="3" rx="1.5" fill="#231F1F"/>
              <rect id="rect-2" y="12" width="33" height="3" rx="1.5" fill="#231F1F"/>
              <rect id="rect-3" y="24" width="33" height="3" rx="1.5" fill="#231F1F"/>
            </svg>
          </div>
          {
            isMenuOpen &&
            <div className="menu-overlay">
              {
                links.map(({ title, link }) =>
                  <a
                    href={link} {...(link.startsWith('#') ? null : { target: '_blank' })}>{title}</a>
                )
              }
            </div>
          }
        </nav>
      </div>
    </header>
  );
};

export default Header;
