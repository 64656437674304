import React, { useState } from 'react';

import './Prices.scss';
import PriceCategory from './PriceCategory';
import ProductCategory from './ProductCategory';

const Prices = () => {
  const [activeCategory, setActiveCategory] = useState(0);

  const Component = [
    <PriceCategory />,
    <ProductCategory />
  ]

  return (
    <div className="prices" id="cenovnik">
      <div className="container">
        <div className="prices-wrapper">
          <div className="categories">
            <div
              className={`category-item ${activeCategory === 0 && 'active'}`}
              onClick={() => setActiveCategory(0)}
            >
              cenovnik <br />usluga

              <div className="box" />
            </div>
            <div
              className={`category-item ${activeCategory === 1 && 'active'}`}
              onClick={() => setActiveCategory(1)}
            >
              cenovnik <br />proizvoda
              <div className="box" />
            </div>
          </div>
          <div className="price-list">
            {Component[activeCategory] || ''}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prices;
